import Glide from '@glidejs/glide'
import lightGallery from 'lightgallery'
import 'lightgallery/scss/lightgallery-bundle.scss'
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen'
import lgPager from 'lightgallery/plugins/pager'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

import './index.scss'

const defaultOptions = {
  autoHeight: true
}
const visibleDots = 7
const minHeightThreshold = 50

$(function () {
  const elements = $('.carousel-component')
  elements.each(function () {
    initComponent($(this))
  })
})

function initComponent (element) {
  const options = element.data('options')
  const images = element.find('img')
  const glide = new Glide(element[0],
    {
      ...defaultOptions,
      ...options,

      // Adjustments
      autoplay: options.autoplay ? 5000 : false,
      type: images.length > 1 ? 'carousel' : 'slider',
      dragThreshold: images.length > 1 ? 120 : false
    })
  let swipeEndTimeout = null

  glide.on('run.before',
    () => {
      element.addClass('is-moving')
    })
  glide.on('move.after',
    () => {
      element.removeClass('is-moving')
    })
  glide.on('swipe.move',
    () => {
      clearTimeout(swipeEndTimeout)
      element.data('is-swiping', true)
    })
  glide.on('swipe.end',
    () => {
      swipeEndTimeout = setTimeout(() => {
          element.data('is-swiping', false)
        },
        300)
    })

  const calcSliderHeight = () => {
    const slideHeight = element.find('.glide__slide--active').outerHeight()
    const glideTrack = element.find('.glide__track').outerHeight()

    if (slideHeight > minHeightThreshold &&
      slideHeight !== glideTrack ||
      element.find('.glide__track').css('height') === null) {
      element.find('.glide__track').animate({ height: `${slideHeight}px` })
    }
  }
  glide.on('build.after', calcSliderHeight)
  glide.on('run.after', calcSliderHeight)

  images.on('load',
    function () {
      const img = $(this)
      const isActive = img.parents('li').hasClass('glide__slide--active')

      if (isActive) {
        calcSliderHeight()
      }
    })

  $(window).on('load', () => glide.mount())

  if (options.enableLightbox) {
    element.find('a').click((e) => {
      if (element.data('is-swiping')) {
        // This is necessary to prevent opening the lightbox mode while
        // swiping the carousel.
        e.preventDefault()
        e.stopImmediatePropagation()
        e.stopPropagation()
      }
    })
    lightGallery(element[0], {
      licenseKey: '0759F4F3-F216423C-9C28B207-1BF53A5E',
      plugins: [lgAutoplay, lgFullscreen, lgPager, lgThumbnail, lgZoom],
      selector: 'li:not(.glide__slide--clone) a',
      exThumbImage: 'data-thumbnail'
    })
  }
}