import AOS from 'aos'

export default function() {
    AOS.init({
        offset: 100,
        duration: 600,
    })

    $(document).on('masonry-grid-render',
        () => {
            AOS.refresh()
        })
}