export function debug(m) {
    if (!window.compositeAppInit.IsLocal) { return }

    console.log('[DEBUG]', m)
}

export function info(m) {
    console.log(m)
}

export function warn(m) {
    console.warn(m)
}

export function error(e) {
    console.error(e)
}

export default {
    debug,
    info,
    warn,
    error
}