import debounce from 'lodash/debounce';
import './index.scss';

let components = []
const scrollIndicatorOffset = 15

function initComponent(element) {
    const nav = element.find('.nav')
    nav.on('scroll', debounce(() => {
        calculateComponentState(element, nav)
    }, 100))

    scrollSelectedItemIntoView(element, nav)
    calculateComponentState(element, nav)
    return element
}

function calculateComponentState(root, element) {
    const scrollOffset = Math.trunc(element.scrollLeft())
    const size = Math.trunc(element.get(0).scrollWidth)
    const containerSize = Math.trunc(element.parent().width())
    const startPos = 0 + scrollIndicatorOffset
    const endPos = size - containerSize - scrollIndicatorOffset

    const showStartOverflowIndicator = scrollOffset > startPos
    const showEndOverflowIndicator = scrollOffset < endPos

    root.find('.start-overflow-indicator').toggleClass('show', showStartOverflowIndicator)
    root.find('.end-overflow-indicator').toggleClass('show', showEndOverflowIndicator)
}

function scrollSelectedItemIntoView(element, nav) {
    const selectedItem = nav.find('.nav-item.active')

    if (selectedItem.length === 0) { return }

    const scrollOffset = Math.trunc(nav.scrollLeft())
    const offset = selectedItem.offset().left

    if (scrollOffset < offset) {
        nav.animate({ scrollLeft: offset - scrollIndicatorOffset * 2 }, 600)
    }
}

$(function () {
    const elements = $('nav.tab-menu-component')

    for (const element of elements) {
        components.push(initComponent($(element)))
    }

    $(window).on('resize', debounce(function () {
        for (const element of components) {
            calculateComponentState(element, element.find('.nav'))
        }
    }, 100))
})