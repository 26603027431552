import fetchWithDefaults from '../utils/fetch';
import setLoaderAnim from '../utils/loaderAnim';

function bind(target, onBindData) {
    const form = $(target).find('form')
    const actionUrl = form.attr('action')
    const method = form.attr('method') || 'post'

    form.submit(async (e) => {
        e.preventDefault()

        try {
            showFetching(form)

            const response = await fetchWithDefaults(actionUrl,
                {
                    method: method.toUpperCase(),
                    body: new FormData(form[0])
                })

            if (response.ok) {
                const contentType = response.headers.get('content-type') || ''

                if (contentType.includes('application/json')) {
                    const result = await response.json()

                    if (!result) {
                        return
                    }

                    if (result.redirectToUrl) {
                        window.locateTo(result.redirectToUrl)
                    }
                } else {
                    const result = await response.text()
                    target.html(result)
                    bind(target, onBindData)
                }
            } else {
                handleError(form, response.statusText)
            }
        } catch (err) {
            handleError(form, err)
        } finally {
            hideFetching(form)
        }
    })

    onBindData($(target))
}

function showFetching(form) {
    const submitBtn = form.find('button[type=submit]')
    submitBtn.prop('disabled', true)
    submitBtn.data('text', submitBtn.text())
    submitBtn.css('width', submitBtn.outerWidth())
    setLoaderAnim(submitBtn)
}

function hideFetching(form) {
    const submitBtn = form.find('button[type=submit]')
    submitBtn.prop('disabled', false)
}

function handleError(form, err) {

}

export default function (target, onBindData) {
    bind(target, onBindData)
}