//import postscribe from 'postscribe'
import './index.scss';

function selector() {
    return $('div.beff1b4931862-component')
}

function initComponent(element) {
    const isInitialized = element.data('initialized')

    if (isInitialized) { return }

    element.data('initialized', true)

    //const id = element.attr('id')
    //const config = window.pushfire.find(x => x.id === id)

    //if (!config) { return }

    //console.log(config)

    //const container = element.find('.content')
    //container.empty()
    //postscribe(container[0], `<script> ${config.content} </script>`)
}

$(function () {
    $(window).on('update-state', function () {
        const elements = selector()
        elements.each(function () {
            const element = $(this)
            initComponent(element)
        })
    })

    const elements = selector()
    elements.each(function () {
        const element = $(this)
        initComponent(element)
    })
})