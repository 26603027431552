import Glide from '@glidejs/glide'
import './index.scss';

const defaultOptions = {
    autoHeight: true
}
const minHeightThreshold = 50

export default function initComponent(element) {
    const items = element.find('li.glide__slide')
    const images = element.find('li.glide__slide img')
    
    const glide = new Glide(element[0],
      {
          ...defaultOptions,

          // Adjustments
          autoplay: 5000,
          type: items.length > 1 ? 'carousel' : 'slider',
          dragThreshold: items.length > 1 ? 120 : false
      })
    let swipeEndTimeout = null

    glide.on('run.before',
      () => {
          element.addClass('is-moving')
      })
    glide.on('move.after',
      () => {
          element.removeClass('is-moving')
      })
    glide.on('swipe.move',
      () => {
          clearTimeout(swipeEndTimeout)
          element.data('is-swiping', true)
      })
    glide.on('swipe.end',
      () => {
          swipeEndTimeout = setTimeout(() => {
                element.data('is-swiping', false)
            },
            300)
      })

    const calcSliderHeight = () => {
        const slideHeight = element.find('.glide__slide--active').outerHeight()
        const glideTrack = element.find('.glide__track').outerHeight()

        if (slideHeight > minHeightThreshold &&
          slideHeight !== glideTrack ||
          element.find('.glide__track').css('height') === null) {
            element.find('.glide__track').animate({ height: `${slideHeight}px` })
        }
    }
    glide.on('build.after', calcSliderHeight)
    glide.on('run.after', calcSliderHeight)

    images.on('load',
      function () {
          const img = $(this)
          const isActive = img.parents('li').hasClass('glide__slide--active')

          if (isActive) {
              calcSliderHeight()
          }
      })

    $(window).on('load', () => glide.mount())
}

window.expandCollapsedEvents = function(e) {
    e.preventDefault()

    const element = $(e.target)
    const ref = element.data('ref')
    const collapsedItems = $(`#${ref}`).find('div.event-item.is-hidden')
    element.parents('div.show-hidden-events-wrapper').hide()
    element.hide()
    collapsedItems.removeClass('is-hidden')
}

$(function () {
    const elements = $('div.brand-list-component')
    elements.each(function () {
        initComponent($(this))
    })
})