export default {
    en: {
        modalTitle: (hint) => `Switch to ${hint.localizations.find(l => l.code === hint.code).name}?`,
        modalText: (current, hint) => `Hey, you are viewing this page in ${current.localizations.find(l => l.code === hint.code).name} right now. Would you prefer to switch to ${hint.nativeName}?`,
        switchToHintLanguageButton: (l) => `Switch to ${l.nativeName}`,
        stayOnSelectedLanguageButton: (current, hint) => `Continue in ${current.localizations.find(l => l.code === hint.code).name}`
    },
    de: {
        modalTitle: (hint) => `Zu ${hint.localizations.find(l => l.code === hint.code).name} wechseln?`,
        modalText: (current, hint) => `Hey, Du siehst diese Seite gerade auf ${current.localizations.find(l => l.code === hint.code).name}. Möchtest Du lieber zu ${hint.nativeName} wechseln?`,
        switchToHintLanguageButton: (l) => `Zu ${l.nativeName} wechseln`,
        stayOnSelectedLanguageButton: (current, hint) => `Weiter auf ${current.localizations.find(l => l.code === hint.code).name}`
    },
    es: {
        modalTitle: (hint) => `¿Cambiar al ${hint.localizations.find(l => l.code === hint.code).name.toLowerCase()}?`,
        modalText: (current, hint) => `Hola, estás viendo esta página en ${current.localizations.find(l => l.code === hint.code).name.toLowerCase()} ahora mismo. ¿Prefieres cambiar al ${hint.nativeName.toLowerCase()}?`,
        switchToHintLanguageButton: (l) => `Cambiar al ${l.nativeName.toLowerCase()}`,
        stayOnSelectedLanguageButton: (current, hint) => `Continuar en ${current.localizations.find(l => l.code === hint.code).name}`
    }
}