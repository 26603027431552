import './index.scss';

async function initComponent(element) {
    const url = element.data('url')
    const frameUrl = `https://www.youtube-nocookie.com/embed/${url}`

    const frame = element.find('iframe')
    frame.attr('src', frameUrl)
}

window.addEventListener('onGdprConsent', (event) => {
    if (event.detail.name !== 'YOUTUBE') {
        return
    }

    $(function () {
        const elements = $('.video-embed-component')

        for (const element of elements) {
            initComponent($(element))
        }
    })
})