export default function (category, action, label) {
    if (window.compositeAppInit.IsLocal) {
        console.log('trackEvent', { category, action, label })
        //return
    }

    // Google Analytics
    //
    // Ref: https://developers.google.com/analytics/devguides/collection/gtagjs/events
    //
    // gtag('event', <action>, {
    //  'event_category': <category>,
    //  'event_label': <label>,
    //  'value': <value>
    // });

    if (window.gtag) {
        window.gtag('event', action, {
            'event_category': category,
            'event_label': label
        })
    }

    // Matomo
    //
    // Ref: https://matomo.org/docs/event-tracking/
    //
    // trackEvent(category, action, [name], [value])

    if (window._paq) {
        window._paq.push(['trackEvent', category, action, label])
    }
}