import fetch from '../../Assets/scripts/utils/fetch';
import './index.scss';

const useReCaptcha = true

$(function () {
    const elements = $('div.vwtr-component')
    elements.each(function () {
        initComponent($(this))
    })
})

function initComponent(element) {
    const options = element.data('options')

    setTimeout(() => trackView(options), options.minTimeout)
}

async function trackView(options) {
    if (!useReCaptcha) {
        fetch(options.url,
            {
                method: 'POST',
                body: ''
            })
    } else {
        if (window.grecaptcha) {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(window.compositeAppInit.GoogleReCaptchaSiteKey, { action: 'track_event_view' }).then(
                    function (token) {
                        fetch(options.url,
                            {
                                method: 'POST',
                                headers: {
                                    'X-Token': token
                                },
                                body: ''
                            })
                    })
            })
        }
    }

    if (window.trackEvent) {
        window.trackEvent(options.trackingCategory, options.trackingAction, options.trackingLabel)
    }
}