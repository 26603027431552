import debounce from 'lodash/debounce'
import logger from '../../Assets/scripts/modules/logger'
import { isElementInView } from '../../Assets/scripts/utils/scrollHelper'
import { findComponentsById } from '../../Assets/scripts/composition/findComponents'

import './index.scss';

const componentId = 'infinite-scroll-view'
const scrollDelay = 100
const cursorRefPrefix = 'cr'
const instances = []

class InfiniteScrollComponent {
    constructor(element) {
        // Create element instances
        this.element = $(element)
        this.container = this.element.find('div.infinite-content')
        this.manualTriggerButton = this.element.find('a.fetch-more-btn')

        // Get props
        this.props = this.element.data('props')
        this.isDebug = this.element.data('debug').toUpperCase() === 'TRUE'

        // Set events
        this.manualTriggerButton.click((e) => this.onManualTriggerButtonClick(e))

        if (this.isDebug) { return }

        this.enable()
    }

    async enable() {
        this.isFetchingMoreDisabled = false
        this.onScrollDebounced = debounce(() => this.onScroll(), scrollDelay)
        $(document).on('scroll', this.onScrollDebounced)

        await this.setInitialCursor()
    }

    disable() {
        this.isFetchingMoreDisabled = true
        this.element.find('div.fetch-more').hide()
        this.element.find('div.no-more-items').toggleClass('visible')
    }

    setLoadingState(isLoading = false) {
        this.isLoading = isLoading
        this.element.find('div.fetch-more div.button').toggleClass('visible')
        this.element.find('div.fetch-more div.loading').toggleClass('visible')
    }

    async fetchMore(skip, take) {
        this.setLoadingState(true)
        this.currentPage++

        try {
            const fetchUrl = this.constructFetchUrl(this.props.feedUrl, { skip, take })
            const response = await fetch(fetchUrl)

            if (!response.ok) {
                logger.error(response.statusText, true)
                return
            }

            const result = await response.json()
            const { skip: newSkip, take: newTake } = result
            this.setCurrentCursor(newSkip, newTake)

            if (result.hasContent) {
                const element = $(result.content)
                this.appendContent(element, this.currentCursor)
                window.updateState()
                this.element.triggerHandler('onContentLoad', [element])
            } else {
                this.disable()
            }

        } finally {
            this.setLoadingState(false)
        }
    }

    appendContent(content, cursor) {
        const cursorfRef = this.createCursorRef(cursor)

        this.container.append(`<a id="cursorref-${cursorfRef}" data-cursor="${btoa(JSON.stringify(cursor))}"></a>`)
        this.container.append(content)
    }

    // #region Event handler

    onScroll() {
        if (this.isLoading) { return }

        const scrollFromTop = $(window).height() + $(window).scrollTop()
        const elementOffset = this.element.offset().top + this.element.outerHeight()

        if (!this.isFetchingMoreDisabled && scrollFromTop >= elementOffset) {
            const { skip, take } = this.getNextCursor()
            this.fetchMore(skip, take)
        }

        const refs = this.container.find('a[id^="cursorref-"]')
        let isCursorSet = false

        for (const ref of refs) {
            if (isElementInView(ref)) {
                const data = atob($(ref).data('cursor'))
                const cursor = JSON.parse(data)
                this.setCursorRef(cursor)
                isCursorSet = true
                break
            }
        }

        if (!isCursorSet) {
            this.setCursorRef(this.currentCursor)
        }
    }

    onManualTriggerButtonClick(e) {
        e.preventDefault()
        const { skip, take } = this.getNextCursor()
        this.fetchMore(skip, take)
    }

    // #endregion

    // #region Cursor handling

    getNextCursor() {
        const { skip, take } = this.currentCursor
        return { skip: skip + take, take }
    }

    setCurrentCursor(skip, take) {
        const cursor = { skip, take }
        this.currentCursor = cursor
    }

    setCursorRef(cursor) {
        const cursorRef = this.createCursorRef(cursor)
        history.replaceState(null, null, `#${cursorRef}`)
    }

    async setInitialCursor() {
        let isCursorSet = false
        let skip = 0, take = 0, scrollFromTop = 0

        //if (window.location.hash && window.location.hash.startsWith('#')) {
        //    const cursor = this.deserializeCursor(window.location.hash.substring(1))

        //    if (cursor) {
        //        isCursorSet = true
        //        this.setCurrentCursor(cursor.skip, cursor.take)
        //        skip = this.props.skip
        //        take = cursor.skip + cursor.take
        //        scrollFromTop = cursor.scrollPosition
        //    }
        //}

        if (!isCursorSet) {
            this.setCurrentCursor(this.props.skip, this.props.take)
            const cursor = this.getNextCursor()
            skip = cursor.skip
            take = cursor.take
        }

        logger.debug(`[InfiniteScrollComponent] Using the following initial fetch props: skip = ${skip}, take = ${take}.`)
        await this.fetchMore(skip, take)

        if (scrollFromTop > 0) {
            setTimeout(() => window.scrollTo(0, scrollFromTop), 200)
        }
    }

    // #endregion

    // #region Helper methods

    constructFetchUrl(urlString, cursor) {
        const url = new URL(urlString, window.location.origin)
        url.searchParams.append('skip', cursor.skip)
        url.searchParams.append('take', cursor.take)

        return url.toString()
    }

    createCursorRef(cursor) {
        return this.serializeCursor(cursor)
    }

    serializeCursor(cursor) {
        const scrollFromTop = Math.trunc($(window).scrollTop())

        return btoa(`${cursorRefPrefix}${cursor.skip}:${cursor.take}:${scrollFromTop}`)
    }

    deserializeCursor(value) {
        value = atob(value)

        if (!value.includes(cursorRefPrefix)) {
            return false
        }

        value = value.replace(cursorRefPrefix, '')
        const parts = value.split(':')

        return { skip: parseInt(parts[0]), take: parseInt(parts[1]), scrollPosition: parseInt(parts[2]) }
    }

    // #endregion
}

function init() {
    initInstances()
}

function initInstances() {
    const components = findComponentsById(componentId)

    for (const element of components) {
        const instance = new InfiniteScrollComponent(element)
        instances.push(instance)
    }
}

$(function () {
    init()
})