import './index.scss';

export default function initComponent(element) {
    element = $(element)
    const albums = element.find('.partyphoto-album-item')

    albums.hover(function () {
        startPreviewAnim($(this))
    }, function () {
        stopPreviewAnim($(this))
    })
}

function startPreviewAnim(element) {
    const images = element.find('img.preview')
    const imageCount = images.length

    if (imageCount <= 1) { return }

    const loop = setInterval(() => {
        const activeIndex = images.index(element.find('img.active'))
        let newIndex = 0

        if (activeIndex < imageCount - 1) {
            newIndex = activeIndex + 1
        }

        images.eq(activeIndex).removeClass('active')
        images.eq(newIndex).addClass('active')
    }, 400)
    element.data('loop-ref', loop)
}

function stopPreviewAnim(element) {
    const loopRef = element.data('loop-ref')
    if (loopRef) { clearInterval(loopRef) }
}

$(function () {
    const elements = $('.partyphoto-album-list-component')

    for (const element of elements) {
        initComponent(element)
    }
})