function embedScript(id, url) {
    const script = document.getElementsByTagName('script')[0]
    const element = document.createElement('script')
    element.type = 'text/javascript'
    element.async = true
    element.defer = true
    element.id = id
    element.src = url
    script.parentNode.appendChild(element, script)
}

export default function () {
    // Browser update
    // ! Currently not necessary
    //embedScript('buoop', '//browser-update.org/update.min.js')
}
