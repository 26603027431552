export function isElementInView(element, isFullyInView = false) {
    const pageTop = $(window).scrollTop()
    const pageBottom = pageTop + $(window).height()
    const elementTop = $(element).offset().top
    const elementBottom = elementTop + $(element).height()

    if (isFullyInView) {
        return ((pageTop < elementTop) && (pageBottom > elementBottom))
    } else {
        return ((elementTop <= pageBottom) && (elementBottom >= pageTop))
    }
}