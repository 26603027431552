import React, { PureComponent } from 'react';
import orderBy from 'lodash/orderBy';
import {DateTime} from "luxon";

const itemLinkDefaultProps = {
    className: 'stretched-link no-target-indicator text-reset',
    target: '_blank'
}

export class LocationResultItem extends PureComponent {
    render() {
        const { item, options } = this.props
        const { currentLanguageId, localization, locationClosedState } = options
        const { localizations, urlOfDetailPage, pictureMedium, addressZipCode, addressCity, isPremiumLocation, locationState } = item
        const itemLocalization = localizations.find(x => x.languageId === currentLanguageId)
        const locationTagConnections = item.locationTagConnections || []
        const tags = orderBy(locationTagConnections.map(x => {
            const tagLocalization = x.locationTag.localizations.find(x => x.languageId === currentLanguageId)

            return {
                ...x.locationTag,
                localization: tagLocalization
            }
        }), [
            x => x.category === 'Location',
            x => x.category === 'Priority1',
            x => x.category === 'Priority2',
            'index',
            'name'
        ], ['desc', 'desc', 'desc', 'desc', 'asc'])

        return (
            <div className="item item-type-location row flex-nowrap no-gutters">
                <div className="col-auto mr-2">
                    {pictureMedium ? (
                        <img className="img-fluid" src={pictureMedium} alt={itemLocalization.name} />
                    ) : (
                        <div className="img" />
                    )}
                </div>
                <div className="col position-static overflow-hidden">
                    <a {...itemLinkDefaultProps} href={urlOfDetailPage}>
                        <strong className="d-block text-truncate">{itemLocalization.name}</strong>
                    </a>
                    <div className="details">
                        {addressZipCode} {addressCity}
                    </div>
                    <div className="tags">
                        {isPremiumLocation && <span className="tag badge badge-pill badge-danger mr-1">{localization.location_premium}</span>}
                        {locationState === locationClosedState && <span className="tag badge badge-pill badge-dark mr-1">{localization.location_closedState}</span>}
                        {tags.map(tag => <span key={tag.id} className="tag badge-hashtag">{tag.localization.name}</span>)}
                    </div>
                </div>
            </div>
        )
    }
}

export class EventResultItem extends PureComponent {
    render() {
        const { item, options } = this.props
        const { currentLanguageId } = options
        const { localizations, beginning, location, eventFlyers, urlOfDetailPage } = item
        const itemLocalization = localizations.find(x => x.languageId === currentLanguageId)
        const locationLocalization = location.localizations.find(x => x.languageId === currentLanguageId)
        const eventTagConnections = item.eventTagConnections || []
        const tags = orderBy(eventTagConnections.map(x => {
            const tagLocalization = x.eventTag.localizations.find(x => x.languageId === currentLanguageId)

            return {
                ...x.eventTag,
                localization: tagLocalization
            }
        }), ['index', 'name'], ['desc', 'asc'])

        return (
            <div className="item item-type-event row flex-nowrap no-gutters" data-cy="item-search-results">
                <div className="col-auto mr-2">
                    {eventFlyers && eventFlyers.length > 0 ? (
                        <img className="img-fluid" src={eventFlyers[0].imageMedium} alt={itemLocalization.name} />
                    ) : (
                        <div className="img" />
                    )}
                </div>
                <div className="col position-static overflow-hidden">
                    <a {...itemLinkDefaultProps} href={urlOfDetailPage}>
                        <strong className="d-block text-truncate">{itemLocalization.name}</strong>
                    </a>
                    <div className="details">
                        <span>{locationLocalization.name}</span>
                        <span className="seperator" />
                        <span>{DateTime.fromISO(beginning).toLocaleString(DateTime.DATE_SHORT)}</span>
                        <span className="seperator" />
                        <span>{DateTime.fromISO(beginning).toLocaleString(DateTime.TIME_SIMPLE)}</span>
                    </div>
                    {tags.length > 0 && <div className="tags">
                        {tags.map(tag => <span key={tag.id} className="tag badge-hashtag">{tag.localization.name}</span>)}
                    </div>}
                </div>
            </div>
        )
    }
}

export class PartyphotoResultItem extends PureComponent {
    render() {
        const { item, options } = this.props
        const { currentLanguageId } = options
        const { localizations, eventDate, urlOfDetailPage, coverImageUrl } = item
        const localization = localizations.find(x => x.languageId === currentLanguageId)

        console.log(item)

        return (
            <div className="item item-type-event">
                <a {...itemLinkDefaultProps} href={urlOfDetailPage}>
                    <strong className="d-block">{localization.eventName}</strong>
                </a>
                <img src={coverImageUrl} alt="" />
                <div className="details">
                    <span>{localization.locationName}</span>
                    <span className="seperator" />
                    <span>{DateTime.fromISO(eventDate).toLocaleString(DateTime.DATE_SHORT)}</span>
                    <span className="seperator" />
                    <span>{DateTime.fromISO(eventDate).toLocaleString(DateTime.TIME_SIMPLE)}</span>
                </div>
            </div>
        )
    }
}