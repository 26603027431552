import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie'
import locales from './locales'

const COOKIE_NAME = 'dismisslanghint'

export default function HintModal(props) {
    const {
        hintLanguage,
        currentLanguage,
        browserLanguage,
        availableLanguages
    } = props

    const [isOpen, setOpen] = useState(false)
    const locale = locales[browserLanguage]
    const hintLanguageUrl = $(`#switch-language-target-url-${hintLanguage.code}`).attr('href')

    useEffect(() => {
        $('#switch-lang-hint-modal').on('hide.bs.modal', handleDismissHint)
        
        return () => {
            $('#switch-lang-hint-modal').off('hide.bs.modal', handleDismissHint)
        }
    }, [])
    useEffect(() => {
        // Check if dismiss cookie was set
        if (Cookies.get(COOKIE_NAME)) {
            return
        }

        setOpen(true)
    }, [])
    useEffect(() => {
        const modal = $('#switch-lang-hint-modal')
        isOpen ? modal.modal('show') : modal.modal('hide')
    }, [isOpen])

    const handleDismissHint = () => {
        Cookies.set(COOKIE_NAME, '1', {expires: 30})
        setOpen(false)
    }

    return (
        <div id="switch-lang-hint-modal" className="modal fade" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {locale.modalTitle(hintLanguage)}
                        </h5>
                        <button 
                            type="button" 
                            className="close" 
                            data-bs-dismiss="modal" 
                            aria-label="Close"
                            onClick={handleDismissHint}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            {locale.modalText(
                                availableLanguages.find(l => l.code === currentLanguage),
                                hintLanguage
                            )}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <a
                            href={hintLanguageUrl} 
                            className="btn btn-brand btn-raised"
                            data-cy="switchBtn"
                        >
                            {locale.switchToHintLanguageButton(hintLanguage)}
                        </a>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            data-cy="dismissBtn"
                            onClick={handleDismissHint}
                        >
                            {locale.stayOnSelectedLanguageButton(availableLanguages.find(l => l.code === currentLanguage), hintLanguage)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}