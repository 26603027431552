import 'core-js/stable'
import 'regenerator-runtime/runtime'
//import 'whatwg-fetch'
import jQuery from '@progress/kendo-ui/node_modules/jquery'
import 'bootstrap/js/src/alert'
import 'bootstrap/js/src/button'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/popover'
import 'bootstrap/js/src/tab'
import 'bootstrap/js/src/tooltip'
import 'bootstrap/js/src/util'
import { Settings } from 'luxon'
import 'iframe-resizer'

import './common'
import './euCookieLaw'
//import './embetty' --> now using Source Point
import './browser'
import './jquerySetup'
import { documentReady } from './dom'

import initApp from './app/init'

// Global used components
import '../../Components/SwitchLanguageHint'
import '../../Components/Breadcrumb'
import '../../Components/MainMenu'
import '../../Components/TabMenu'
import '../../Components/ScrollToTop'
import '../../Components/AdCampaign'
import '../../Components/AdPlacement'
import '../../Components/SearchOverlay'
import '../../Components/WhatsappButton'
import '../../Components/InstagramButton'

import './angularInit'

window.$ = window.jQuery = global.jQuery = jQuery

// Default layout constraints
window.layout = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
}

documentReady(function () {
  // Start the application and run all necessary scripts for preparation
  Settings.defaultLocale = window.compositeAppInit.IsoLanguage

  initApp()
})