import './index.scss';

export default function initComponent(element) {
    //element = $(element)
}

window.expandCollapsedEvents = function(e) {
    e.preventDefault()

    const element = $(e.target)
    const ref = element.data('ref')
    const collapsedItems = $(`#${ref}`).find('div.event-item.is-hidden')
    element.parents('div.show-hidden-events-wrapper').hide()
    element.hide()
    collapsedItems.removeClass('is-hidden')
}

$(function () {
    const elements = $('div.event-list-component')
    elements.each(function () {
        initComponent($(this))
    })
})