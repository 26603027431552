export default function (url, options) {
    const mergedOptions = {
        ...options
    }
    mergedOptions.headers = mergedOptions.headers || {}

    // Default header
    mergedOptions.headers['X-Requested-With'] = 'XMLHttpRequest'
    mergedOptions.headers['X-Request-Verification-Token'] = window.compositeAppInit.AccessToken

    return fetch(url, mergedOptions)
}