import trackEvent from './trackEvent';
import externalScripts from './externalScripts';
import enableScrollReveal from './enableScrollReveal';
import emojis from './emojis'

function defineGlobalFunctions() {
    window.trackEvent = trackEvent

    if (window._trackEventShelved && window._trackEventShelved.length > 0) {
        window._trackEventShelved.forEach(function (item) {
            window.trackEvent(item.category, item.action, item.label)
        })
    }

    window.updateState = function () {
        $(window).triggerHandler('update-state')
    }
}

export default function () {
    defineGlobalFunctions()
    externalScripts()
    enableScrollReveal()
    emojis()
}