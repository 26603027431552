import React from 'react';
import ReactDOM from 'react-dom';

// ReSharper disable once InconsistentNaming
export default function(Component, element, props = null) {
  // Get all data props for props of the react component
  props = {
    ...props,
  };

  for (const key in element.dataset) {
    if (!element.dataset.hasOwnProperty(key)) continue;
    props[key] = element.dataset[key];
  }

  if (element.hasAttribute('id')) props.id = element.getAttribute('id');

  // Render a new instance of the component and add all found props
  ReactDOM.render(<Component {...props}/>, element);
}