import React, { Component, PureComponent, Fragment } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import cx from 'classnames'
import renderComponent from '../../Assets/scripts/composition/renderComponent'
import trackEvent from '../../Assets/scripts/app/trackEvent'
import fetch from '../../Assets/scripts/utils/fetch'
import { LocationResultItem, EventResultItem, PartyphotoResultItem } from './types.js'
import './index.scss'

const apiUrlTemplate =
    (query, pagination) => `/api/v2/search/query?q=${query}&pagination=${btoa(JSON.stringify(pagination))}`
const types = {
    location: {
        component: LocationResultItem,
        paging: {
            from: 0,
            size: 5,
            total: 0
        }
    },
    event: {
        component: EventResultItem,
        paging: {
            from: 0,
            size: 5,
            total: 0
        }
    },
    partyphotoAlbum: {
        component: PartyphotoResultItem,
        paging: {
            from: 0,
            size: 5,
            total: 0
        }
    }
}

class SearchOverlay extends Component {
    constructor (props) {
        super(props)
        this.options = this.parseOptions()
        this.state = {
            isLoading: false,
            isOpen: false,
            searchInput: this.getInitialSearchQuery(),
            searchResult: null,
            currentUrl: document.location.href.replace(/^(?:\/\/|[^\/]+)*\//, '/'),
            pagination: this.getPaginationForState(types)
        }
        this.searchInputRef = React.createRef()
        this.queryDebounced = debounce(this.fetch, 500)
    }

    componentDidMount () {
        const { isOpenSearchOnLoadEnabled, triggerSelector } = this.options

        if (isOpenSearchOnLoadEnabled) {
            setTimeout(() => {
                    this.showSearch()

                    const initialQuery = this.getInitialSearchQuery()

                    if (initialQuery && initialQuery.length > 0) {
                        this.fetch(initialQuery)
                    }
                },
                1000)
        }

        if (triggerSelector) {
            const trigger = $(triggerSelector)
            trigger.click(this.handleTriggerClick)
        }
    }

    componentWillUnmount () {
        const { triggerSelector } = this.options

        this.hideSearch()

        if (triggerSelector) {
            $(triggerSelector).off('click', this.handleTriggerClick)
        }
    }

    getInitialSearchQuery = () => {
        return this.options.searchQuery || ''
    }

    showSearch = () => {
        this.setState({
                isOpen: true,
                searchInput: this.getInitialSearchQuery(),
                searchResult: null,
                pagination: this.getPaginationForState(types)
            },
            () => {
                this.searchInputRef.current.focus()
            })

        $('body').toggleClass('no-scroll', true)

        trackEvent('SearchOverlay', 'Show')
    }

    hideSearch = () => {
        this.setState({
            isOpen: false,
            isLoading: false
        })
        $('body').toggleClass('no-scroll', false)
        history.replaceState(null, '', this.state.currentUrl)

        $(window).trigger('search:close')

        trackEvent('SearchOverlay', 'Hide')
    }

    handleTriggerClick = e => {
        e.preventDefault()
        this.showSearch()
    }

    handleSearchInputChange = e => {
        const value = e.target.value
        this.setState({
                searchInput: value,
                pagination: this.getPaginationForState(types)
            },
            () => {
                if (value.length > 2) {
                    this.queryDebounced(value)
                }

                history.replaceState(null, '', `/de/suche?q=${encodeURIComponent(value.trim())}`)
            })
    }

    triggerSearch = () => {
        this.fetch(this.state.searchInput)
    }

    fetch = async (query) => {
        const { pagination } = this.state
        this.setState({ isLoading: true })
        trackEvent('SearchOverlay', 'Search', (query || '').trim().toLowerCase())
        const response = await fetch(apiUrlTemplate(query, pagination))
        const searchResult = await response.json()

        this.setState({ isLoading: false, searchResult, pagination: this.getPaginationForState(types, searchResult) })
    }

    parseOptions = () => {
        return JSON.parse(this.props.options || '{}')
    }

    getPaginationForState = (types, apiResult = null) => {
        const state = {}
        const keys = Object.keys(types)

        for (const key of keys) {
            state[key] = {
                ...types[key].paging
            }
        }

        if (apiResult && apiResult.isSuccessful) {
            const groups = Object.keys(apiResult.groups)

            for (const key of groups) {
                const group = apiResult.groups[key]
                state[key].from = group.from
                state[key].count = group.count
                state[key].total = group.total
            }
        }

        return state
    }

    render () {
        const { isOpen } = this.state
        const { localization } = this.options

        return (
            <div className={cx('search-container', { show: isOpen })}>
                {isOpen &&
 (
     <>
<div className="header ui-component index-1">
    <div className="container">
        <img width="240" height="32" src={localization.brandLogo} alt=""/>
        {this.renderCloseHandle()}
    </div>
</div>
<div className="container">
    {this.renderSearchInput()}
    {this.renderProgress()}
    {this.renderResultColumns()}
</div>
</>
 )}
            </div>
        )
    }

    renderCloseHandle = () => {
        return (
            <button className="close-handle ui-component index-2" onClick={this.hideSearch}>
                <span className="material-icons icon">close</span>
            </button>
        )
    }

    renderSearchInput = () => {
        const { searchInput } = this.state
        const { localization } = this.options

        return (
            <div className="row align-items-center search-input ui-component index-3">
                <div className="col">
                    <input ref={this.searchInputRef} type="text" placeholder={localization.inputPlaceholder
} data-cy="search-text-box" value={searchInput}
                           onChange={this.handleSearchInputChange}/>
                </div>
                <button className="col-auto d-flex align-items-center" onClick={this.triggerSearch}>
                    <span className="material-icons icon">search</span>
                </button>
            </div>
        )
    }

    renderProgress = () => {
        const { isLoading } = this.state

        return (
            <div className={cx('progress', { ['is-loading']: isLoading })}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="..."/>
            </div>
        )
    }

    renderError = () => {
        const { localization } = this.options

        return (
            <div className="search-error">
                {localization.searchError}
            </div>
        )
    }

    renderEmptySearchResult = () => {
        const { localization } = this.options

        return (
            <Fragment>
                <div className="empty-search-result">
                    {localization.emptySearchResult}
                </div>
                {this.renderSearchSuggestions()}
            </Fragment>
        )
    }

    renderSearchSuggestions = () => {
        const { localization } = this.options
        const tips = get(localization, 'searchTips', '').split(';')
        const title = get(localization, 'searchTipsTitle', '')

        return (
            <div className="search-suggestions ui-component index-4">
                {title}
                <ul>
                    {tips.map(x =>
                        <li key={x}>
                            <a href="#" id={x} onClick={this.handleSuggestion(x)}>{x}</a>
                        </li>
                    )}
                </ul>
            </div>
        )
    }

    renderResultColumns = () => {
        const { searchResult } = this.state

        if (!searchResult) {
            return this.renderSearchSuggestions()
        }

        // TODO: TEST
        //searchResult.isSuccessful = false

        if (!searchResult.isSuccessful) {
            return this.renderError()
        }

        const groups = Object
            .keys(searchResult.groups)
            .filter(key => searchResult.groups.hasOwnProperty(key))
            .filter(type => searchResult.groups[type].documents.length > 0)

        if (groups.length === 0) {
            return this.renderEmptySearchResult()
        }

        return (
            <div className="search-results">
                {groups.map(type => this.renderSearchResultForType(type, searchResult.groups[type]))}
            </div>
        )
    }

    renderSearchResultForType = (type, searchResult) => {
        const { localization } = this.options
        const { pagination } = this.state
        const { documents: items } = searchResult
        const groupTitle = localization[type]
        const ComponentClass = types[type].component
        const pagingForType = get(pagination, type)

        return (
            <div key={type} className="search-result-group" data-cy="search-result-group">
                <div className="d-flex align-items-center group-title">
                    {groupTitle}
                    <span className="badge badge-light badge-pill ml-auto">{pagingForType.total}</span>
                </div>
                <div className="items">
                    {items.map(item => <ComponentClass key={item.id} item={item} options={this.options}/>)}
                </div>
                {this.renderPagination(type,
                    pagingForType.total,
                    pagingForType.from,
                    pagingForType.size,
                    pagingForType.showDuraingFetch)}
            </div>
        )
    }

    renderPagination = (type, count = 0, currentIndex = 0, size = 0, showPagination = false) => {
        const { isLoading } = this.state

        if ((count === 0 || size === 0) && !showPagination) {
            return null
        }
        if (currentIndex < 0) currentIndex = 0
        if (currentIndex > count - 1) currentIndex = count - 1

        if (currentIndex === 0 && count <= size) return null

        return (
            <div className="paging d-flex justify-content-center">
                <nav>
                    <ul className="pagination">
                        <li className={cx('page-item', { disabled: isLoading || currentIndex === 0 })}>
                            <a className="page-link" href="#" onClick={this.handlePaging('start',
                                type,
                                count,
                                currentIndex,
                                size)}>
                                <span className="material-icons icon">first_page</span>
                            </a>
                        </li>
                        <li className={cx('page-item', { disabled: isLoading || currentIndex === 0 })}>
                            <a className="page-link" href="#"
                               onClick={this.handlePaging('backward', type, count, currentIndex, size)}>
                                <span className="material-icons icon">chevron_left</span>
                            </a>
                        </li>
                        <li className={cx('page-item', { disabled: isLoading || currentIndex >= count - size })}>
                            <a className="page-link" href="#" onClick={this.handlePaging('forward',
                                type,
                                count,
                                currentIndex,
                                size)}>
                                <span className="material-icons icon">chevron_right</span>
                            </a>
                        </li>
                        <li className={cx('page-item', { disabled: isLoading || currentIndex >= count - size })}>
                            <a className="page-link" href="#" onClick={this.handlePaging('end',
                                type,
                                count,
                                currentIndex,
                                size)}>
                                <span className="material-icons icon">last_page</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }

    handlePaging = (seek, type, count, currentIndex, size) => {
        return e => {
            e.preventDefault()
            const pagination = this.getPaginationForState(types)
            let newIndex = 0

            switch (seek) {
            case 'start':
                newIndex = 0
                break
            case 'backward':
                newIndex = currentIndex - size

                if (newIndex < 0) newIndex = 0
                break
            case 'forward':
                newIndex = currentIndex + size

                if (newIndex > count - 1) newIndex = count - 1
                break
            case 'end':
                {
                    let factor = Math.floor(count / size)

                    if (factor * size === count) factor -= 1

                    newIndex = factor * size
                }
                break
            }

            pagination[type].from = newIndex
            pagination[type].showDuraingFetch = true
            this.setState({ pagination }, this.triggerSearch)
            trackEvent('SearchOverlay', 'Paging', JSON.stringify({ seek, type, count, currentIndex, newIndex, size }))
        }
    }

    handleSuggestion = (value) => (event) => {
        event.preventDefault()

        this.handleSearchInputChange({
            target: { value }
        })
    }
}

function initComponent(element) {
    renderComponent(SearchOverlay, element)
}

$(function() {
    const elements = $('div.search-overlay-component')

    for (const element of elements) {
        initComponent(element)
    }
})