import renderComponent from '../../Assets/scripts/composition/renderComponent';
import HintModal from './HintModal'
import './index.scss'

function initComponent(element) {
    const {
        IsoLanguage: currentLanguage,
        AvailableLanguages: availableLanguages
    } = window.compositeAppInit
    let browserLanguage = navigator.language;
    
    if(!browserLanguage || browserLanguage.length < 2) {
        return
    }
    
    if(browserLanguage.length > 2) {
        browserLanguage = browserLanguage.substring(0, 2)
    }
    
    if(browserLanguage === currentLanguage) {
        return;
    }
    
    const hintLanguage = availableLanguages.find(l => l.code === browserLanguage)
    
    if(!hintLanguage) {
        return
    }

    renderComponent(HintModal, element, {
        hintLanguage,
        currentLanguage,
        browserLanguage,
        availableLanguages
    })
}

$(function() {
    const element = $('.switch-language-hint-component')
    
    initComponent(element[0])
})