import './index.scss';

function selector() {
    return $('div.e2956a7c5469f9dc-component')
}

function initComponent(element) {
    const isInitialized = element.data('initialized')

    if (isInitialized) { return }

    const zone = element.data('zone')
    const cssClassIfContent = element.data('cssclassifcontent')
    const id = `fxYQ2vSRSjypSp0sBGpwziJiMMkarHQ5-${Math.floor((Math.random() * 100) + 100)}`
    element.attr('id', id)
    element.data('initialized', true)

    const screenWidth = Math.abs(Math.round($(window).width()))
    const screenHeight = Math.abs(Math.round($(window).height()))

    if (element.is(':visible')) {
        if (window.compositeAppInit.IsLocal) {
            console.log(`Loading ads for zone: ${zone}`)
        }

        fetch(`/api/v2/campaign/${zone}`,
            {
                method: 'POST',
                body: JSON.stringify({
                    AdParams: window.adParams,
                    ScreenWidth: screenWidth,
                    ScreenHeight: screenHeight,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (!data || !data.content) {
                    return
                }

                const content = element.find('.content')

                if (data.preContent) {
                    element.find('.pre-content').append(data.preContent)
                }

                content.prepend(data.content)

                if (data.postContent) {
                    element.find('.post-content').append(data.postContent)
                }

                if (!content.find('> div:visible, > iframe:visible').length) {
                    return
                }

                element.find('.indicator').removeClass('d-none')

                if (cssClassIfContent) {
                    element.addClass(cssClassIfContent)
                }

                if (data.content.includes('iframe')) {
                    element.find('.indicator').addClass('d-none')

                    const frame = element.find('iframe')
                    frame.on('load',
                        () => {
                            const frameContent = frame.contents()
                            const hasContent = frameContent.find('iframe, div.has-content').length

                            if (!hasContent) {
                                return
                            }

                            frame.css('width', frame.data('width'))
                            frame.css('height', frame.data('height'))
                            element.find('.indicator').removeClass('d-none')
                        })

                    //element.find('.content').addClass('use-max-width')

                    //for (const frame of element.find('iframe.use-resize')) {
                    //    iFrameResize({ checkOrigin: false, log: window.compositeAppInit.IsLocal }, frame)
                    //}
                }
            })
    }
}

$(function () {
    $(window).on('update-state', function () {
        const elements = selector()
        elements.each(function () {
            const element = $(this)
            initComponent(element)
        })
    })

    const elements = selector()
    elements.each(function () {
        const element = $(this)
        initComponent(element)
    })
})