import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Fade from 'react-reveal/Fade'

import './index.scss';

class FilterBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCollapsed: true
        }
    }

    toggle = () => {
        this.setState({ isCollapsed: !this.state.isCollapsed })
    }

    renderGroup = group => {
        return (
            <div key={group.name} className="group">
                <div className="title">
                    <span>{group.name}</span>
                </div>
                <div className="options">
                    {group.options.map(option => this.renderOption(option))}
                </div>
            </div>
        )
    }

    renderOption = option => {
        return (
            <a key={option.value} className="badge badge-pill badge-hashtag option" href={option.value} onClick={this.toggle}>{option.name}</a>
        )
    }

    render() {
        const { data } = this.props
        const { isCollapsed } = this.state
        const { groups } = data

        return (
            <Fade collapse when={!isCollapsed}>
                <div className="xcard wrapper">
                    <div className="xcard-body groups">
                        {groups.map(group => this.renderGroup(group))}
                    </div>
                    <div className="toggle">
                        <a className="btn btn-block btn-outline-light" href="#" onClick={this.toggle}>Schließen</a>
                    </div>
                </div>
            </Fade>
        )
    }
}

export default class {
    constructor(element = null) {
        this.element = element || $('#default-filter-bar').get(0)

        this.mount()
    }

    mount() {
        const data = $(this.element).data('data')
        ReactDOM.render(<FilterBar data={data} ref={component => this.component = component} />, this.element)
    }

    toggle() {
        this.component.toggle()
    }
}