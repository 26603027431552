import React, {PureComponent} from 'react';
import {DateTime} from "luxon";
import humanizeDuration from 'humanize-duration'
import renderComponent from '../../Assets/scripts/composition/renderComponent';
import './index.scss';

class Countdown extends PureComponent {
    componentDidMount() {
        this.trigger = setInterval(this.trigger, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.trigger)
    }

    trigger = () => {
        this.forceUpdate()
    }

    render() {
        let {date, text} = this.props
        const now = DateTime.now()
        date = DateTime.fromISO(date)
        text = text || ''

        if (date < now) {
            return null
        }

        const duration = humanizeDuration(now.diff(date).milliseconds, {
            language: window.compositeAppInit.IsoLanguage,
            round: true,
            largest: 1
        })

        return (
            <span>
                {text} {duration}
            </span>
        )
    }
}

function init(element) {
    renderComponent(Countdown, element)
}

$(function () {
    const elements = $('.countdown-component')

    for (const element of elements) {
        init(element)
    }
})