import './index.scss';

const element = $('#root-nav');
const trigger = element.find('.toggle-navbar');
const collapsible = element.find('#navbar-1st-level-content');

trigger.click(function () {
    prepareNavPaneMobile();
    element.toggleClass('expanded');
    trigger.toggleClass('collapsed');
});

$(function () {
    prepareNavPaneMobile();
});

function prepareNavPaneMobile() {
    const viewportHeight = $(window).height();
    const navbarHeight = element.outerHeight();

    collapsible.css('top', navbarHeight);
    collapsible.css('height', viewportHeight - navbarHeight);
}