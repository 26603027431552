import './index.scss';

const element = $('a.scroll-to-top-component')

element.click(function () {
    $("html, body").animate({
        scrollTop: 0
    }, 600)
    return false
})

$(window).scroll(function () {
    ($(this).scrollTop() > 100)
        ? element.addClass('visible')
        : element.removeClass('visible')
})
